<template>
  <v-parallax
    dark
    :src="topImage"
    height="500"
  >
    <v-overlay absolute opacity="0.4">
      <v-container>
        <h1 :class="[$vuetify.breakpoint.mdAndUp ? 'display-3' : 'display-2']" class="display-3 text-center">WELCOME TO SUDBURY</h1>
        <h4 class="text-h4 text-center my-4">Seventh-day Adventist Church</h4>
        <!-- <v-row class="d-flex justify-center mt-8">
          <v-btn
            large
            outlined
            color="transparant"
            href="/letter"
          >LETTER FROM THE PASTOR</v-btn>
        </v-row> -->
      </v-container>
    </v-overlay>
  </v-parallax>
</template>

<script>
export default {
  name: 'HomeParallax',
  data: () => {
    return {
      homeImages: [
        require('@/assets/images/home_slide/home_slide1.jpg'),
        require('@/assets/images/home_slide/home_slide2.jpg'),
        require('@/assets/images/home_slide/home_slide3.jpg'),
        require('@/assets/images/home_slide/home_slide4.jpg'),
        require('@/assets/images/home_slide/home_slide5.jpg'),
        require('@/assets/images/home_slide/home_slide6.jpg'),
        require('@/assets/images/home_slide/home_slide7.jpg')
      ],
      topImage: require('@/assets/images/home_banner.jpg')
    }
  }
}
</script>
