<template>
  <v-card rounded="0" color="#282828">
    <v-row class="pb-4">
      <v-col cols="12" lg="8">
        <event-banners></event-banners>
        <h4 class="h4 text-center mt-2" style="color:white">Monthly Newsletter</h4>
        <v-row class="d-flex justify-center mt-2">
          <v-btn href="/bulletins" outlined style="color:white">News From the Pews</v-btn>
        </v-row>
      </v-col>
      <v-col cols="12" lg="4">
        <v-card>
          <v-system-bar
            color="#4CBB17"
            height="2"
            dark
          ></v-system-bar>
          <list-calendar></list-calendar>
        </v-card>
        <h5 class="h4 text-center mt-2" style="color:white">Weekly Church Bulletin</h5>
        <h5 class="h5 text-center mt-2" style="color:white">Every week our bulletin is posted online for you to read.</h5>
        <v-row class="d-flex justify-center mt-2">
          <!-- <v-btn href="bulletinUrl" target="_blank" outlined style="color:white">Read This Week's Bulletin</v-btn> -->
          <v-btn href="/documents/2023_NEO_CM_Registration.pdf" target="_blank" outlined style="color:white">Campmeeting Registration</v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import EventBanners from '@/components/EventBanners.vue'
import ListCalendar from '@/components/ListCalendar.vue'

export default {
  components:
    {
      EventBanners,
      ListCalendar
    },
  computed: {
    ...mapGetters('app', {
      bulletinUrl: 'bulletinUrl'
    })
  },
  async mounted() {
    this.$store.dispatch('app/retrieveBulletin')
  }
}
</script>
