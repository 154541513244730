<template>
  <div>
    <home-parallax></home-parallax>
    <home-welcome></home-welcome>
    <home-events></home-events>
  </div>
</template>

<script>
import HomeParallax from '@/components/HomeParallax.vue'
import HomeWelcome from '@/components/HomeWelcome.vue'
import HomeEvents from '@/components/HomeEvents.vue'

export default {
  components: {
    HomeParallax,
    HomeWelcome,
    HomeEvents
  },
  head: {
    title: function () {
      return {
        inner: 'Home'
      }
    }
  }
}
</script>
