<template>
  <v-card rounded="0" class="pt-6">
    <v-container>
      <v-row no-gutters>
        <v-col cols="12" lg="4" >
        
          <v-card flat>
            <h4 class="h4 mt-4"><b>News from the Pews:</b></h4>
            <h5 class="h4 mt-4 mb-2"><a style="color:grey" class="text-decoration-none" target="_blank" href="newsletter/Feb. 2020 NEWSLETTER.pdf">February 2020</a></h5> <hr class="mr-12">
            <h5 class="h4 mt-4 mb-2"><a style="color:grey" class="text-decoration-none" target="_blank" href="newsletter/Sudbury-JAN. 2020 NEWSLETTER.pdf">January 2020</a></h5> <hr class="mr-12">
            <h5 class="h4 mt-4 mb-2"><a style="color:grey" class="text-decoration-none" target="_blank" href="newsletter/Sudbury-DEC. 2019 NEWSLETTER.pdf">December 2019</a></h5> <hr class="mr-12">
            <h5 class="h4 mt-4 mb-2"><a style="color:grey" class="text-decoration-none" target="_blank" href="newsletter/NOV-2019 NEWSLETTER.pdf">November 2019</a></h5> <hr class="mr-12">
            <h5 class="h4 mt-4 mb-2"><a style="color:grey" class="text-decoration-none" target="_blank" href="newsletter/OCT.2019 NEWSLETTER.pdf">October 2019</a></h5>
          </v-card>
        </v-col>
        <v-col cols="12" lg="8">
          <v-container>
            <v-card>
              <v-img :src="imgSrc" max-height="150" />
              <h3
                class="align-start text-center pt-2"
                :class="[$vuetify.breakpoint.mdAndUp ? 'h3' : 'h4']"
                style="min-height: 88px;"
              >
                Church Bulletin Announcements
              </h3>
              <h3 class="mx-4 text-center">
                <b>Church Bulletin Deadline:</b> <br>
                Wednesday noon - 12:00pm <br>
                <b>Bulletin E-mail:</b> <br>
                <a href="mailto:sudburysda@hotmail.com" class="text-decoration-none" style="color:green">sudburysda@hotmail.com</a>
              </h3>
              <v-row class="d-flex justify-center py-4">
                <v-btn
                  large
                  outlined
                  color="transparant"
                  href="/calendar"
                >Calendar of Events</v-btn>
              </v-row>
            </v-card></v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
export default {
  data () {
    return {
      imgSrc: require('@/assets/images/clock.jpg'),
      tab: null,
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
    }
  }
}
</script>