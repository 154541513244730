<template>
  <v-carousel
    class="pa-0"
    cycle
    height="auto"
    :show-arrows-on-hover="true"
    :hide-delimiters="true"
    delimiter-icon="mdi-minus"
  >
    <v-carousel-item
      v-for="(slide, i) in slides"
      :key="i"
      :src="slide.imageUrl"
      :href="slide.linkUrl"
      :target="slide.target"
      reverse-transition="fade-transition"
      fade
      eager
    >
      <v-img :src="slide.imageUrl" eager/>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      loading: true,
      errored: false,
      currentCategoryId: 1,
      slides: [{}]
    }
  },
  async mounted () {
    await this.loadBanners()
  },
  methods: {
    async loadBanners() {
      axios ({
        method: 'get',
        url: this.getBannersApiUrl(),
        headers: await this.getHeaders()
      })
        .then((response) => {
          this.slides = response.data[0].banners.sort((a, b) => Number(a.orderId) - Number(b.orderId))
        })
        .catch((error) => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.loading = false)
    },
    async getHeaders() {
      const accessToken = await this.$msal.acquireToken()

      return {
        Authorization: 'Bearer ' + accessToken,
        'Ocp-Apim-Subscription-Key': process.env.VUE_APP_BANNERS_API_KEY
      }
    },
    getBannersApiUrl() {
      return `${process.env.VUE_APP_BANNERS_API_URL}banners/${process.env.VUE_APP_BANNERS_API_ENTITY}/${this.currentCategoryId}`
    }
  }
}
</script>
