<template>
  <div>
    <FullCalendar :options="calendarOptions" />
    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-title class="headline">{{ title }}</v-card-title>
        <v-card-text>
          <h4 class="pb-2">{{ eventTime }}</h4>
          <p>
            {{ description }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import googleCalendarPlugin from '@fullcalendar/google-calendar'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'

export default {
  name: 'Calendar',
  components: {
    FullCalendar
  },
  data() {
    return {
      title: null,
      description: null,
      eventTime: null,
      dateOptions: {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      },
      timeOptions: { hour: '2-digit', minute: '2-digit' },
      calendarOptions: {
        plugins: [googleCalendarPlugin, listPlugin, interactionPlugin],
        initialView: 'list30Days',
        headerToolbar: null,
        eventTextColor: 'red',
        eventClick: this.eventClick,
        googleCalendarApiKey: 'AIzaSyABxtzsKuYGUpdi2YZkgJXAM_ikPgIBNRc',
        events: {
          googleCalendarId:
            'msu0t3o9qauqh1np8nogj989lk@group.calendar.google.com'
        },
        views: {
          list30Days: {
            type: 'listMonth',
            duration: { days: 30 }
          }
        }
      },
      dialog: false
    }
  },
  methods: {
    eventClick: function(info) {
      console.log('test')
      info.jsEvent.preventDefault()
      this.dialog = true // don't let the browser navigate
      this.title = info.event.title
      const regex = /(<([^>]+)>)/gi

      this.description = info.event.extendedProps.description.replace(
        regex,
        ''
      )
      this.eventTime = info.event.start.toLocaleDateString(
        'en-US',
        this.dateOptions
      )
      if (!info.event.allDay) {
        this.eventTime +=
          '<br />' +
          info.event.start.toLocaleString('en-US', this.timeOptions) +
          ' - ' +
          info.event.end.toLocaleString('en-US', this.timeOptions)
      }
      this.eventTime = info.event.start
    }
  }
}
</script>

<style>
.close_btn {
  float: right;
}
</style>
