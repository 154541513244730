<template>
  <v-card>
    <v-alert
      color="#2A3B4D"
      dark
      class="ma-auto"
      dense
      rounded="0"
      height="100"
    >
      <!-- <a href="/documents/bulletins/current.pdf" target="_blank" class="text-decoration-none"> --> 
      <v-row class="d-flex justify-center mt-2" no-gutters>
        <v-btn
          large
          width="300"
          color="transparant"
          :href="bulletinUrl"
          target="_blank"
        >This Week's Bulletin</v-btn>
      </v-row>
    </v-alert>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CovidAlert',
  data () {
    return {
      alert: true
    }
  },
  computed: {
    ...mapGetters('app', {
      bulletinUrl: 'bulletinUrl'
    })
  },
  async mounted() {
    this.$store.dispatch('app/retrieveBulletin')
  }
}
</script>